import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faCamera, 
  faHome, 
  faMapMarkerAlt, 
  faBroom, 
  faUsers, 
  faRobot, 
  faCog,
  faBed, 
  faBath, 
  faRuler, 
  faClock,
  faLocationDot,
  faUserTie,
  faUser,
  faImage,
  faPencilAlt, 
  faCheck, 
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

// Initialize FontAwesome library with all icons
library.add(
  faCamera, 
  faHome, 
  faMapMarkerAlt, 
  faBroom, 
  faUsers, 
  faRobot, 
  faCog,
  faBed, 
  faBath, 
  faRuler, 
  faClock,
  faLocationDot,
  faUserTie,
  faUser,
  faImage,
  faPencilAlt, 
  faCheck, 
  faTimes,
  faTrash
);

// Export individual icons for direct usage if needed
export {
  faCamera, 
  faHome, 
  faMapMarkerAlt, 
  faBroom, 
  faUsers, 
  faRobot, 
  faCog,
  faBed, 
  faBath, 
  faRuler, 
  faClock,
  faLocationDot,
  faUserTie,
  faUser,
  faImage,
  faPencilAlt, 
  faCheck, 
  faTimes,
  faTrash
};