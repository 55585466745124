import React, { useState, useEffect, useContext } from 'react';
import { getPayout } from '../../../api/payoutsApi';
import styles from './PayoutDetails.module.css';
import { format } from 'date-fns';
import { FaTimes } from 'react-icons/fa';
import { UserContext } from '../../../context/UserContext';

const PayoutDetails = ({ payoutId, onClose }) => {
  const [payout, setPayout] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useContext(UserContext);
  useEffect(() => {
    const fetchPayoutDetails = async () => {
      try {
        const data = await getPayout(payoutId);
        setPayout(data);
      } catch (err) {
        setError('Failed to load payout details');
      } finally {
        setLoading(false);
      }
    };

    fetchPayoutDetails();
  }, [payoutId]);

  const formatCurrency = (cents, currency) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(cents / 100);
  };

  if (loading) return <div className={styles.loading}>Loading...</div>;
  if (error) return <div className={styles.error}>{error}</div>;
  if (!payout) return null;

  return (
    <div className={styles.modalContent}>
      <div className={styles.header}>
        <h2>Payout Details</h2>
        <button onClick={onClose} className={styles.closeButton}>
          <FaTimes />
        </button>
      </div>

      <div className={styles.section}>
        <h3>General Information</h3>
        <div className={styles.info}>
          <p><strong>Date:</strong> {format(new Date(payout.date), 'MMMM d, yyyy')}</p>
          <p><strong>Total Amount:</strong> {formatCurrency(payout.total_amount_cents, payout.total_amount_currency)}</p>
        </div>
      </div>

      <div className={styles.section}>
        <h3>{user.current_role === 'cleaner' ? 'Manager' : 'Cleaner'}</h3>
        <div className={styles.info}>
          <p><strong>Name:</strong> {
            user.current_role === 'cleaner' 
              ? `${payout.manager.first_name} ${payout.manager.last_name}`
              : `${payout.cleaner.first_name} ${payout.cleaner.last_name}`
          }</p>
          <p><strong>Email:</strong> {
            user.current_role === 'cleaner'
              ? payout.manager.email
              : payout.cleaner.email
          }</p>
        </div>
      </div>

      <div className={styles.section}>
        <h3>Jobs</h3>
        <div className={styles.tableWrapper}>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Property</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {payout.payout_jobs.map(job => (
                <tr key={job.id}>
                  <td>{format(new Date(job.date_of_job), 'MMM d, yyyy')}</td>
                  <td>{job.property.title}</td>
                  <td>{formatCurrency(job.amount_cents, job.amount_currency)}</td>
                </tr>
              ))}
              <tr className={styles.totalRow}>
                <td colSpan="2"><strong>Total</strong></td>
                <td><strong>{formatCurrency(
                  payout.payout_jobs.reduce((sum, job) => sum + job.amount_cents, 0),
                  payout.payout_jobs[0]?.amount_currency || 'USD'
                )}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {payout.payout_adjustments.length > 0 && (
        <div className={styles.section}>
          <h3>Adjustments</h3>
          <div className={styles.tableWrapper}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {payout.payout_adjustments.map(adjustment => (
                  <tr key={adjustment.id}>
                    <td>{adjustment.calculation_details.rule_name}</td>
                    <td>{adjustment.calculation_details.rule_description}</td>
                    <td>{formatCurrency(adjustment.amount_cents, adjustment.amount_currency)}</td>
                  </tr>
                ))}
                <tr className={styles.totalRow}>
                  <td colSpan="2"><strong>Total Adjustments</strong></td>
                  <td><strong>{formatCurrency(
                    payout.payout_adjustments.reduce((sum, adj) => sum + adj.amount_cents, 0),
                    payout.payout_adjustments[0]?.amount_currency || 'USD'
                  )}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PayoutDetails;