import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from '../../../context/UserContext';
import { fetchProperties } from '../../../api/propertiesApi';
import { Link } from 'react-router-dom';
import styles from './PropertiesIndex.module.css';
import { BiTime } from 'react-icons/bi';
import { IoLocationOutline } from 'react-icons/io5';
import { FaEuroSign, FaDollarSign } from 'react-icons/fa';
import { TbCurrencyReal } from 'react-icons/tb';

const PropertiesIndex = () => {
  const { user } = useContext(UserContext);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    const getProperties = async () => {
      try {
        const data = await fetchProperties();
        setProperties(data);
      } catch (error) {
        console.error('Failed to fetch properties', error);
      }
    };

    getProperties();
  }, []);

  console.log(properties);

  const formatPrice = (cents, currency) => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: currency || 'EUR'
    }).format(cents / 100);
  };

  const getCurrencyIcon = (currency) => {
    switch (currency) {
      case 'EUR':
        return <FaEuroSign className={styles.icon} />;
      case 'USD':
        return <FaDollarSign className={styles.icon} />;
      case 'BRL':
        return <TbCurrencyReal className={styles.icon} />;
      default:
        return <FaEuroSign className={styles.icon} />; // fallback to Euro
    }
  };

  const PropertyCard = ({ property }) => {
    const isManager = user.current_role === 'manager';
    
    return (
      <Link 
        to={`/cleaning/properties/${property.id}`} 
        className={styles.cardLink}
      >
        <div className={styles.card}>
          <div className={styles.imageContainer}>
            <img 
              src={property.photo_url} 
              alt={property.title}
              className={styles.propertyImage}
            />
          </div>
          <div className={styles.content}>
            <h2 className={styles.title}>{property.title}</h2>
            
            <div className={styles.infoItem}>
              <IoLocationOutline className={styles.icon} />
              <p className={styles.address}>{property.address}</p>
            </div>
            
            <div className={styles.infoItem}>
              <BiTime className={styles.icon} />
              <div className={styles.schedule}>
                <span className={styles.label}>Cleaning hours:</span>
                <span className={styles.time}>
                  {property.default_cleaning_from} - {property.default_cleaning_until}
                </span>
              </div>
            </div>
            
            <div className={styles.infoItem}>
              {getCurrencyIcon(property.default_job_price_currency)}
              <div className={styles.pricing}>
                <span className={styles.label}>
                  {isManager ? 'Price per cleaning:' : 'Earnings per cleaning:'}
                </span>
                <span className={styles.amount}>
                  {formatPrice(
                    property.default_job_price_cents,
                    property.default_job_price_currency
                  )}
                </span>
              </div>
            </div>
            
            {property.description && (
              <div className={styles.descriptionContainer}>
                <p className={styles.description}>{property.description}</p>
              </div>
            )}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.pageTitle}>
        {user.current_role === 'manager' ? 'Your Properties' : 'Properties you are cleaning'}
      </h1>
      <div className={styles.grid}>
        {properties.map(property => (
          <PropertyCard key={property.id} property={property} />
        ))}
      </div>
      {user.current_role === 'manager' && (
        <div className={styles.addPropertyContainer}>
          <Link to="/cleaning/properties/new" className={styles.addButton}>
            Add Property
          </Link>
        </div>
      )}
    </div>
  );
};

export default PropertiesIndex;
