import apiClient from './apiClient';

export const createPayout = async (data) => {
    try {
        const response = await apiClient.post('/api/payouts', data);
        return response.data;
    } catch (error) {
        console.error('Error creating payout:', error);
        throw error;
    }
};

export const getPayouts = async (startDate, endDate) => {
    try {
        const params = {};
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;
        
        const response = await apiClient.get('/api/payouts', { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching payouts:', error);
        throw error;
    }
};

export const getPayout = async (payoutId) => {
    try {
        const response = await apiClient.get(`/api/payouts/${payoutId}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching payout:', error);
        throw error;
    }
};