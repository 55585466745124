import React from 'react';
import MapComponent from '../../components/MapBox/MapComponent';
import styles from './CleanerPropertyCard.module.css';

// Icons (you'll need to import these from your icon library)
import { 
  ClockIcon, 
  CurrencyEuroIcon, 
  HomeIcon, 
  MapPinIcon, 
  UserIcon 
} from '@heroicons/react/24/outline';

export default function CleanerPropertyCard({ property }) {
  console.log(property);
  const owner = property.owner || { first_name: 'Unknown', last_name: '' };
  
  // Calculate cleaning duration in hours
  const startTime = new Date(`2000/01/01 ${property.default_cleaning_from}`);
  const endTime = new Date(`2000/01/01 ${property.default_cleaning_until}`);
  const durationHours = (endTime - startTime) / (1000 * 60 * 60);

  return (
    <div className={styles.propertyCard}>
      <div className={styles.propertyContent}>
        {/* Left Column */}
        <div>
          {/* Hero Section with Image */}
          <section className={styles.heroSection}>
            <div className={styles.imageContainer}>
              <img 
                src={property.photo_url} 
                alt={property.title}
                className={styles.propertyImage}
              />
            </div>
            <div className={styles.propertyInfo}>
              <h1>{property.title}</h1>
              <p>{property.description}</p>
            </div>
          </section>

          {/* Financial Section */}
          <section className={styles.financialSection}>
            <div className={styles.sectionTitle}>
              <CurrencyEuroIcon className={styles.icon} />
              <h2>Earnings Per Clean</h2>
            </div>
            <div className={styles.earningsBox}>
              <span className={styles.amount}>
                €{(property.default_job_price_cents / 100).toFixed(2)}
              </span>
            </div>
          </section>
        </div>

        {/* Right Column */}
        <div>
          {/* Schedule Section */}
          <section className={styles.scheduleSection}>
            <div className={styles.sectionTitle}>
              <ClockIcon className={styles.icon} />
              <h2>Cleaning Window</h2>
            </div>
            <div className={styles.timeBox}>
              <div className={styles.timeSlot}>
                <span className={styles.timeLabel}>Available From</span>
                <span className={styles.time}>{property.default_cleaning_from}</span>
              </div>
              <div className={styles.timeSlot}>
                <span className={styles.timeLabel}>Must Be Done By</span>
                <span className={styles.time}>{property.default_cleaning_until}</span>
              </div>
            </div>
            <div className={styles.durationInfo}>
              Please ensure cleaning is completed within this time window
            </div>
          </section>

          {/* Property Details Section */}
          <section className={styles.detailsSection}>
            <div className={styles.sectionTitle}>
              <HomeIcon className={styles.icon} />
              <h2>Property Details</h2>
            </div>
            <div className={styles.detailsGrid}>
              <div className={styles.detail}>
                <span className={styles.detailLabel}>Size</span>
                <span className={styles.detailValue}>{property.square_meters} m²</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailLabel}>Bedrooms</span>
                <span className={styles.detailValue}>{property.number_rooms}</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailLabel}>Bathrooms</span>
                <span className={styles.detailValue}>{property.number_bathrooms}</span>
              </div>
              <div className={styles.detail}>
                <span className={styles.detailLabel}>Supplies Provided</span>
                <span className={styles.detailValue}>
                  {property.has_cleaning_supplies ? 'Yes' : 'No'}
                </span>
              </div>
            </div>
          </section>

          {/* Owner Section */}
          <section className={styles.ownerSection}>
            <div className={styles.sectionTitle}>
              <UserIcon className={styles.icon} />
              <h2>Property Owner</h2>
            </div>
            <div className={styles.ownerCard}>
              <div className={styles.ownerInfo}>
                <h3>{owner.first_name} {owner.last_name}</h3>
                <span className={styles.ownerEmail}>
                  {owner.email || 'Email not available'}
                </span>
              </div>
            </div>
          </section>

          {/* Location Section */}
          <section className={styles.locationSection}>
            <div className={styles.sectionTitle}>
              <MapPinIcon className={styles.icon} />
              <h2>Location</h2>
            </div>
            <div className={styles.address}>
              <p>{property.address}</p>
            </div>
            <div className={styles.mapContainer}>
              <MapComponent 
                address={property.address}
                latitude={property.latitude}
                longitude={property.longitude}
                onAddressChange={() => {}}
              />
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
