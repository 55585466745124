import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaFileDownload, FaCircle } from 'react-icons/fa';
import styles from './Payouts.module.css';
import { format, parse } from 'date-fns';
import { getPayouts } from '../../../api/payoutsApi';
import { UserContext } from '../../../context/UserContext';
import PayoutDetails from './PayoutDetails';

export default function Payouts() {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  console.log(user);
  const [periodType, setPeriodType] = useState('month');
  const [selectedMonth, setSelectedMonth] = useState(format(new Date(), 'MMMM yyyy'));
  const [dateRange, setDateRange] = useState({
    from: '',
    until: ''
  });

  const [payoutsData, setPayoutsData] = useState({ payouts: [], statistics: {} });
  const [selectedPayout, setSelectedPayout] = useState(null);

  const formatCurrency = (amount, currency = 'USD') => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency
    }).format(amount);
  };

  const getLastTwelveMonths = () => {
    const months = [];
    const today = new Date();
    for (let i = 0; i < 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
      const formattedDate = format(date, 'MMMM yyyy');
      months.push(formattedDate);
    }
    return months;
  };

  const handlePeriodTypeChange = (type) => {
    setPeriodType(type);
    if (type === 'month') {
      setDateRange({ from: '', until: '' });
    } else {
      setSelectedMonth('');
    }
  };

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedMonth(newMonth);
  };

  const fetchPayouts = async () => {
    try {
      let startDate, endDate;
      
      if (periodType === 'month' && selectedMonth) {
        const date = parse(selectedMonth, 'MMMM yyyy', new Date());
        
        if (isNaN(date.getTime())) {
          return;
        }

        startDate = format(new Date(date.getFullYear(), date.getMonth(), 1), 'yyyy-MM-dd');
        endDate = format(new Date(date.getFullYear(), date.getMonth() + 1, 0), 'yyyy-MM-dd');
      } else if (periodType === 'custom') {
        startDate = dateRange.from;
        endDate = dateRange.until;
      }

      const data = await getPayouts(startDate, endDate);
      setPayoutsData(data);
    } catch (error) {
      console.error('Failed to fetch payouts:', error);
    }
  };

  console.log('The payouts are', payoutsData);
  useEffect(() => {
    fetchPayouts();
  }, []);
  useEffect(() => {
    fetchPayouts();
  }, [selectedMonth, dateRange.from, dateRange.until, periodType]);

  const groupPayoutsByCurrency = (payouts) => {
    return payouts.reduce((acc, payout) => {
      const currency = payout.total_amount_currency;
      if (!acc[currency]) {
        acc[currency] = {
          totalCents: 0,
          count: 0
        };
      }
      acc[currency].totalCents += payout.total_amount_cents;
      acc[currency].count += 1;
      return acc;
    }, {});
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Payouts</h1>
        {user?.current_role === 'manager' && (
          <div className={styles.actions}>
            <button 
            className={styles.createButton}
            onClick={() => navigate('/cleaning/payouts/new')}
          >
            <FaPlus /> Create Payout
            </button>
          </div>
        )}
      </div>

      <div className={styles.periodSelector}>
        <div className={styles.periodTypeButtons}>
          <button
            className={`${styles.periodTypeButton} ${periodType === 'month' ? styles.active : ''}`}
            onClick={() => handlePeriodTypeChange('month')}
          >
            Month
          </button>
          <button
            className={`${styles.periodTypeButton} ${periodType === 'custom' ? styles.active : ''}`}
            onClick={() => handlePeriodTypeChange('custom')}
          >
            Custom Range
          </button>
        </div>
        
        {periodType === 'month' ? (
          <select 
            value={selectedMonth}
            onChange={handleMonthChange}
            className={styles.periodSelect}
          >
            {getLastTwelveMonths().map(month => (
              <option key={month} value={month}>{month}</option>
            ))}
          </select>
        ) : (
          <div className={styles.dateRangeInputs}>
            <input
              type="date"
              value={dateRange.from}
              onChange={(e) => setDateRange(prev => ({ ...prev, from: e.target.value }))}
              className={styles.dateInput}
              placeholder="Start date"
            />
            <span className={styles.dateSeparator}>to</span>
            <input
              type="date"
              value={dateRange.until}
              onChange={(e) => setDateRange(prev => ({ ...prev, until: e.target.value }))}
              className={styles.dateInput}
              placeholder="End date"
            />
          </div>
        )}
      </div>

      <div className={styles.statsCards}>
        <div className={styles.statCard}>
          <h3>Total Payouts</h3>
          {Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).length > 0 ? (
            Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).map(([currency, data]) => (
              <p key={currency}>
                {formatCurrency(data.totalCents / 100, currency)}
              </p>
            ))
          ) : (
            <p>0</p>
          )}
          <span>Including jobs and adjustments</span>
        </div>
        <div className={styles.statCard}>
          <h3>Total Jobs</h3>
          <p>{payoutsData.payouts.length > 0 ? 
            payoutsData.payouts.reduce((sum, payout) => sum + payout.jobs_count, 0) : 
            '0'
          }</p>
          <span>This period</span>
        </div>
        <div className={styles.statCard}>
          <h3>Average Per Payout</h3>
          {Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).length > 0 ? (
            Object.entries(groupPayoutsByCurrency(payoutsData.payouts)).map(([currency, data]) => (
              <p key={currency}>
                {formatCurrency(data.count > 0 ? data.totalCents / data.count / 100 : 0, currency)}
              </p>
            ))
          ) : (
            <p>0</p>
          )}
          <span>This period</span>
        </div>
        <div className={styles.statCard}>
          <h3>Unpaid Jobs</h3>
          {Object.entries(payoutsData.statistics.unpaid_jobs_by_currency || {}).length > 0 ? (
            Object.entries(payoutsData.statistics.unpaid_jobs_by_currency || {}).map(([currency, cents]) => (
              <p key={currency}>
                {formatCurrency(cents / 100, currency)}
              </p>
            ))
          ) : (
            <p>0</p>
          )}
          <span>Jobs not yet included in payouts</span>
        </div>
      </div>

      <div className={styles.tableContainer}>
        <div className={styles.desktopView}>
          <table className={styles.payoutsTable}>
            <thead>
              <tr>
                <th>{user.current_role === 'cleaner' ? 'Manager' : 'Cleaner'}</th>
                <th>Period</th>
                <th>Amount</th>
                <th>Jobs</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {payoutsData.payouts.map((payout) => (
                <tr 
                  key={payout.id}
                  onClick={() => setSelectedPayout(payout.id)}
                  className={styles.tableRow}
                >
                  <td>{user.current_role === 'cleaner' 
                    ? `${payout.manager.first_name} ${payout.manager.last_name}`
                    : `${payout.cleaner.first_name} ${payout.cleaner.last_name}`
                  }</td>
                  <td>{format(new Date(payout.date), 'MMMM yyyy')}</td>
                  <td>{formatCurrency(payout.total_amount_cents / 100, payout.total_amount_currency)}</td>
                  <td>{payout.jobs_count}</td>
                  <td>{format(new Date(payout.date), 'yyyy-MM-dd')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className={styles.mobileView}>
          {payoutsData.payouts.map((payout) => (
            <div 
              key={payout.id}
              onClick={() => setSelectedPayout(payout.id)}
              className={styles.mobileTableRow}
            >
              <div className={styles.mobileTableHeader}>
                <span>{user.current_role === 'cleaner'
                  ? `${payout.manager.first_name} ${payout.manager.last_name}`
                  : `${payout.cleaner.first_name} ${payout.cleaner.last_name}`
                }</span>
                <span>{formatCurrency(payout.total_amount_cents / 100, payout.total_amount_currency)}</span>
              </div>
              <div className={styles.mobileTableDetails}>
                <div>
                  <label>Jobs:</label>
                  <span>{payout.jobs_count}</span>
                </div>
                <div>
                  <label>Period:</label>
                  <span>{format(new Date(payout.date), 'MMMM yyyy')}</span>
                </div>
                <div>
                  <label>Date:</label>
                  <span>{format(new Date(payout.date), 'yyyy-MM-dd')}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedPayout && (
        <div className={styles.modalOverlay} onClick={(e) => {
          if (e.target === e.currentTarget) setSelectedPayout(null);
        }}>
          <PayoutDetails 
            payoutId={selectedPayout} 
            onClose={() => setSelectedPayout(null)} 
          />
        </div>
      )}
    </div>
  );
}
