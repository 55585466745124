import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { UserContext } from '../../../context/UserContext';
import styles from './MobileNavbar.module.css';
import { 
  FaCalendarAlt, 
  FaHome, 
  FaEnvelope, 
  FaChartBar, 
  FaBars, 
  FaSearch, 
  FaSignOutAlt,
  FaMoneyBill 
} from 'react-icons/fa';

export default function MobileNavbar() {
  const { user, logout } = useContext(UserContext);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [showFinancialMenu, setShowFinancialMenu] = useState(false);
  const menuRef = useRef(null);
  const financialMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMenu && menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
      if (showFinancialMenu && financialMenuRef.current && !financialMenuRef.current.contains(event.target)) {
        setShowFinancialMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu, showFinancialMenu]);

  const handleLogout = async () => {
    await logout();
    navigate('/cleaning');
    setShowMenu(false);
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
    setShowFinancialMenu(false);
  };

  const toggleFinancialMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowFinancialMenu(!showFinancialMenu);
    setShowMenu(false);
  };

  return (
    <div className={styles.mobileNavbarWrapper}>
      <nav className={styles.mobileNavbar}>
        <NavLink to="/cleaning/calendar" className={styles.navItem}>
          <FaCalendarAlt className={styles.icon} />
          <span>Calendar</span>
        </NavLink>
        <NavLink to="/cleaning/properties" className={styles.navItem}>
          <FaHome className={styles.icon} />
          <span>Properties</span>
        </NavLink>
        {/* <NavLink to="/cleaning/messages" className={styles.navItem}>
          <FaEnvelope className={styles.icon} />
          <span>Messages</span>
        </NavLink> */}
        {user && (
          <button onClick={toggleFinancialMenu} className={styles.navItem}>
            <FaChartBar className={styles.icon} />
            <span>Financial</span>
          </button>
        )}
        <button onClick={toggleMenu} className={styles.navItem}>
          <FaBars className={styles.icon} />
          <span>Menu</span>
        </button>
      </nav>

      {showFinancialMenu && (
        <div ref={financialMenuRef} className={styles.menu}>
          <NavLink 
            to="/cleaning/dashboard" 
            className={styles.menuItem} 
            onClick={() => setShowFinancialMenu(false)}
          >
            <FaChartBar className={styles.icon} />
            <span>Dashboard</span>
          </NavLink>
          <NavLink 
            to="/cleaning/payouts" 
            className={styles.menuItem} 
            onClick={() => setShowFinancialMenu(false)}
          >
            <FaMoneyBill className={styles.icon} />
            <span>Payouts</span>
          </NavLink>
        </div>
      )}

      {showMenu && (
        <div ref={menuRef} className={styles.menu}>
          {/* <NavLink 
            to="/cleaning/find-user" 
            className={styles.menuItem} 
            onClick={() => setShowMenu(false)}
          >
            <FaSearch className={styles.icon} />
            <span>Find Cleaner</span>
          </NavLink> */}
          <button onClick={handleLogout} className={styles.menuItem}>
            <FaSignOutAlt className={styles.icon} />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}
