import apiClient from './apiClient';

// Fetch all users
export const fetchUsers = async () => {
  try {
    const response = await apiClient.get('/api/users');
    return response.data.users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

// Fetch all cleaners
export const fetchCleaners = async () => {
  try {
    const response = await apiClient.get('/api/cleaners');
    return response.data.cleaners;
  } catch (error) {
    console.error('Error fetching cleaners:', error);
    throw error;
  }
};

// Switch user role
export const switchUserRole = async (currentRole) => {
  try {
    const response = await apiClient.post(`/api/users/switch-role`, { currentRole });
    return response.data;
  } catch (error) {
    console.error('Error switching user role:', error);
    throw error;
  }
};